import React from 'react';

import {Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {CustomBuiltSanityPage} from 'src/templates/customPages';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components/macro';
import useSWR from 'swr';

/**
 * Query used to get a draft Page Builder document for Preview.
 *
 * If you update this, be sure to update PAGE_BUIlDER_GROQ_QUERY as well.
 */
export const PAGE_BUILDER_PREVIEW_GROQ_QUERY = `
* [_type == "pageBuilder" && slug.current==$slug && (
  _id in path("drafts.**") || !defined(*[_id == "drafts." + ^._id][0])
)]{
  ...,
  'components': components[]{
    _type == 'pageBuilderLeadFormSection' => @{..., 'leadForm': leadForm->},
    _type == 'pageBuilderHero' => {
      ...,
      'children': children[]{
        _type == 'pageBuilderLeadFormSection' => @{..., 'leadForm': leadForm->},
        _type != 'pageBuilderLeadFormSection' => @
      }
    },
    !(_type in ['pageBuilderLeadFormSection', 'pageBuilderHero']) => @
  },
}[0]
`;

const PageBuilderDraft: React.FC<{params: {slug: string}}> = ({params}) => {
  const {data, error} = useSWR([PAGE_BUILDER_PREVIEW_GROQ_QUERY, params.slug], (query, slug) =>
    sanityClient.fetch(query, {slug}),
  );

  if (error) {
    return (
      <Container>
        <Typography.H1 color="white">Sorry, there was an error retrieving the data for this custom page</Typography.H1>
        <Typography.P0 color="white">Error details: {JSON.stringify(error)}</Typography.P0>
      </Container>
    );
  }

  if (data === undefined) {
    return (
      <Container>
        <Typography.P0 color="white">Loading...</Typography.P0>;
      </Container>
    );
  }

  if (data === null) {
    return (
      <Container>
        <Typography.P0 color="white">Sorry, we could not find a page with the provided slug</Typography.P0>
      </Container>
    );
  }

  return <CustomBuiltSanityPage pageData={data} />;
};

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
})``;

export default PageBuilderDraft;
